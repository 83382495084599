import axios from 'axios'
import { fetchAllHydra } from '@/helpers/rest'
import jwt_decode from 'jwt-decode'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logged: false,
    token: null,
    drawer: null,
    decodedToken: null,
    salePoints: [],
    categories: [],
    drops: [],
    families: [
      'vintage',
      'imperfect',
      'archive',
      'upcycled',
      'new_but_green',
    ],
    taxonomiesAttributes: [],
    taxonomies: {},
    tags: [],
    carriers: [
      { default: true,  tracked: true, label: 'Colissimo',      value: 'COLISSIMO' },
      { default: true,  tracked: true, label: 'Mondial Relay',  value: 'MONDIAL_RELAY' },
      { default: true,  tracked: true, label: 'BPost',          value: 'BPOST' },
      { default: true,  tracked: true, label: 'Chronopost',     value: 'CHRONOPOST' },
      { default: false, tracked: true, label: 'DHL',            value: 'DHL' },
      { default: false, tracked: true, label: 'Poste Italiane', value: 'POSTE_ITALIANE' },
      { default: false, tracked: true, label: 'GLS',            value: 'GLS' },
      { default: false, tracked: true, label: 'Colis Privé',    value: 'COLIS_PRIVE' },
      { default: false, tracked: true, label: 'DPD',            value: 'DPD' },
      { default: false, tracked: false, label: 'UPS',           value: 'UPS' },
      { default: false, tracked: false, label: 'Inconnu',       value: 'UNKNOWN' },
    ],
  },
  mutations: {
    login(state, token) {
      window.localStorage.setItem('token', token)
      state.token = token
      state.logged = true
      state.decodedToken = jwt_decode(token)
      state.roles = state.decodedToken.roles
    },
    logout(state) {
      window.localStorage.removeItem('token')
      state.token = null
      state.logged = false
    },
    drawerDisplay(state, display) {
      state.drawer = display
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_DROPS(state, drops) {
      state.drops = drops
    },
    SET_TAXONOMIES(state, { taxonomies, kind }) {
      state.taxonomies = { ...state.taxonomies, [kind]: taxonomies }
    },
    SET_TAXONOMY_ATTRIBUTES(state, taxonomyAttributes) {
      state.taxonomyAttributes = taxonomyAttributes
    },
    SET_TAGS(state, tags) {
      state.tags = tags
    },
    SET_SALE_POINTS(state,  salePoints) {
      state.salePoints = salePoints
    },
  },
  actions: {
    loadSalePoints({ commit }) {
      Vue.axios.get('sale_points')
        .then(response => {
          let salePoints = response.data['hydra:member']
          salePoints.sort((a, b)=> (a.name > b.name ? 1 : -1))
          commit('SET_SALE_POINTS', salePoints)
        })
    },
    loadCategories({ commit }) {
      Vue.axios.get('v3/categories?itemsPerPage=200')
        .then(response => {
          let categories = response.data['hydra:member']
          categories.sort((a, b) => a.full_names.fr.localeCompare(b.full_names.fr))
          commit('SET_CATEGORIES', categories)
        })
    },
    async loadTaxonomyAttributes({ commit }) {
      const taxonomyAttributes = await fetchAllHydra(Vue.axios, 'v3/taxonomy_attributes?itemsPerPage=200')

      commit('SET_TAXONOMY_ATTRIBUTES', taxonomyAttributes)
    },
    async loadTaxonomies({ commit }) {
      const taxonomyPerKind = {}
      const taxonomies = await fetchAllHydra(Vue.axios, 'v3/taxonomies?itemsPerPage=200')
      taxonomies.sort((a, b) => (a.name > b.name ? 1 : -1))
        .forEach(taxonomy => {
          if (!taxonomyPerKind[taxonomy.kind]) {
            taxonomyPerKind[taxonomy.kind] = []
          }
          taxonomyPerKind[taxonomy.kind].push(taxonomy)
        })

      for (const kind in taxonomyPerKind) {
        commit('SET_TAXONOMIES', { taxonomies: taxonomyPerKind[kind], kind })
      }
    },
    async loadTags({ commit }) {
      commit(
        'SET_TAGS',
        await fetchAllHydra(Vue.axios, 'catalog/tags/?itemsPerPage=200')
      )
    },
    loadDrops({ commit }) {
      const GET_DROPS_GQL = `
        query drops {
          drops(allowUnpublished: true) {
            page(page: 1) {
              id
              slug
              name
            }
          }
        }
      `

      axios.post(process.env.VUE_APP_MARKETPLACE_GRAPHQL_URL, { query: GET_DROPS_GQL })
        .then(({ data }) => {
          let drops = data.data.drops.page
          commit('SET_DROPS', drops)
        })
    },
  },
  modules: {
  },
  getters: {
    isLogged: state => {
      return state.token !== null && state.token !== undefined
    },
    isAdmin: state => state.decodedToken.roles.includes('ROLE_ADMINISTRATOR'),
    isInternalModerator: state => state.decodedToken.roles.includes('ROLE_INTERNAL_MODERATOR'),
    isExternalModerator: state => state.decodedToken.roles.includes('ROLE_EXTERNAL_MODERATOR'),
    roles: state => state.decodedToken.roles,
  },
})
