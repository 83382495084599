<template>
  <v-select
    v-model="source"
    hide-details
    clearable
    outlined
    dense
    item-text="label"
    item-value="value"
    :items="values"
    :label="inputLabel('Source', required)"
    :error-messages="error"
    @change="$emit('update:error', null)"
  />
</template>

<script>
import inputMixin from '@/mixins/input'

export default {
  name: 'SourceSelector',
  mixins: [ inputMixin ],
  props: {
    value: {
      type: String,
      default: null,
    },
    required: Boolean,
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      values: [
        {
          label: this.$i18n.t('views.external_catalog.classifications.enum.sources.shopify'),
          value: 'shopify',
        },
        {
          label: this.$i18n.t('views.external_catalog.classifications.enum.sources.csv-default'),
          value: 'csv-default',
        },
      ],
    }
  },
  computed: {
    source: {
      get () { return this.value  },
      set (value) { this.$emit('input', value) },
    },
  },
}
</script>
